import React from "react"
import { Link } from "gatsby"
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';

import { rhythm, scale } from "../utils/typography"

const styles = theme => ({
  "@global": {
    body: {
      backgroundColor: "transparent"
    }
  }
});

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  //let header

  //if (location.pathname === rootPath) {
    //header = (
    //  <h1
    //    style={{
    //      ...scale(1.5),
    //      marginBottom: rhythm(1.5),
    //      marginTop: 0,
    //    }}
    //  >
    //    <Link
    //      style={{
    //        boxShadow: `none`,
    //        color: `inherit`,
    //      }}
    //      to={`/`}
    //    >
    //      {title}
    //    </Link>
    //  </h1>
    //)
  //} else {
  //  header = (
  //    <h3
  //      style={{
  //        fontFamily: `Montserrat, sans-serif`,
  //        marginTop: 0,
  //      }}
  //    >
  //      <Link
  //        style={{
  //          boxShadow: `none`,
  //          color: `inherit`,
  //        }}
  //        to={`/`}
  //      >
  //        {title}
  //      </Link>
  //    </h3>
  //  )
  //}
  return (
		<Container 
			//component="div"
			maxWidth="sm"
		>
			<CssBaseline />
    {/*<div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`
      }}
    >*/}
      {/*<header>{header}</header>*/}
      <main>{children}</main>
      <Box
				component="footer"
				display="flex"
      	flexWrap="nowrap"
				style={{
					backgroundColor: 'rgba(0,36,0,0.03)',
					position: 'absolute',
					left: '0',
					bottom: '0',
					width: '100%',
					height: '50px',
					lineHeight: '50px'
				}}
			>
				<Container maxWidth="sm">
        	<Box
						width="50%"
						component="div"
						display="inline-block"
						alignItems="left"
						style={{
							fontSize: '0.8rem'
						}}
					>
						© LimeCoda {new Date().getFullYear()}
					</Box>
					<Box
						width="50%"
						component="div"
						display="inline-block"
						alignItems="right"
						style={{
							textAlign: 'right'
						}}
					>
						<a href="https://twitter.com/LimeCoda" target="_blank">
							<TwitterIcon style={{
								verticalAlign: 'middle'
							}} />
						</a>
						<a href="https://github.com/limecoda" target="_blank">
							<GitHubIcon style={{
								marginLeft:'10px',
								verticalAlign: 'middle'
							}} />
						</a>
					</Box>
				</Container>
      </Box>
    {/*</div>*/}
		</Container>
  )
}

export default withStyles(styles)(Layout)
